<template>
  <div class="c_guide">
    <div class="stu-module-header">
      <div class="stu-module-title">操作指引</div>
    </div>

    <div class="content">毕业论文（设计）</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.c_guide {
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;
  .content {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 14px;
  }
}
</style>